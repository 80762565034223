import React, { useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import { getAccessToken } from "../../components/auth/HandleCookies";

function TermsAndConditions() {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Terms & Conditions | Host" : "Terms & Conditions | HRMS";
  }, []);
  return (
    <>
      <div className="heading">
        <span>Terms and Conditions</span>
      </div>
      <div className="card border-3 shadow-lg container-body mt-2 mb-4">
        <div className="card-body">
          <div style={{ fontFamily: "-moz-initial" }}>
            <p>
              <IntlMessages id="term.and.condition.l1" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.p1" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.l2" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.p2" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.l3" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.p3" />
            </p>
            <h6 className="font-17">
              <IntlMessages id="term.and.condition.h1" />
            </h6>
            <p>
              <IntlMessages id="term.and.condition.h1.l1" />
              <a
                href="https://hrmshost.com"
                target="_blank">
                <IntlMessages id="term.and.condition.h1.l2" />
              </a>
            </p>
            <p>
              <IntlMessages id="term.and.condition.h1.p1" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h1.p2" />
            </p>
            <h6 className="font-17">
              <IntlMessages id="term.and.condition.h2" />
            </h6>
            <p>
              <IntlMessages id="term.and.condition.h2.l1" />
            </p>
            <p>
              <ul>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h2.b1" />
                </li>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h1.b1" />
                </li>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h2.b2" />
                </li>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h2.b3" />
                </li>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h2.b4" />
                </li>
                <li>
                  &#x2022; &nbsp;
                  <IntlMessages id="term.and.condition.h2.b5" />
                </li>
              </ul>
            </p>
            <h6 className="font-17">
              <IntlMessages id="term.and.condition.h3" />
            </h6>
            <p>
              <IntlMessages id="term.and.condition.h3.l1" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1.l1" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1.l2" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1.l3" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1.l4" />
            </p>
            <p className="m-0">
              <IntlMessages id="term.and.condition.h3.p1.l5" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.p1.l6" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.h2" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.2" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.2.p1" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.3" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.4" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.5" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.6" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.7" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.8" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h3.9" />
            </p>
            <h6 className="font-17">
              <IntlMessages id="term.and.condition.h4" />
            </h6>
            <p>
              <IntlMessages id="term.and.condition.h4.p1" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h4.p2" />
            </p>
            <p>
              <IntlMessages id="term.and.condition.h4.p3" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
