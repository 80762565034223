import { React } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./home/LandingPage";
import Protected from "../config/Protected";
import MyOrderWrapper from "./myOrder";
import ToastMessage from "./shared/ToastMessage";
import FAQ from "./faq/FAQ";
import TermsAndConditions from "./companyRegistration/TermsAndConditions";
import PrivacyPolicy from "./companyRegistration/PrivacyPolicy";
import CustomerSupport from "./companyRegistration/CustomerSupport";
import CancellationAndRefund from "./companyRegistration/CancellationAndRefund";
import Footer from "./footer/Footer";
import EmployeeManagement from "../pages/EmployeeManagement";
import PayrollManagement from "../pages/PayrollManagement";
import LeaveAttendance from "../pages/LeaveAttendance";
import CompensationTaxes from "../pages/CompensationTaxes";
import AssetProject from "../pages/AssetProject";
import DocumentManagement from "../pages/DocumentManagement";

const WrapperComponent = () => {
  return (
    <>
      <div className="WrapperComponent">
        <ToastMessage />
        <Router>
          {/* <Navbar /> */}
          <Routes>
            <Route
              exact={true}
              path="/"
              element={<LandingPage />}
            />
            <Route
              exact={true}
              path="/terms-of-service"
              element={<TermsAndConditions />}
            />
            <Route
              exact={true}
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              exact={true}
              path="/support"
              element={<CustomerSupport />}
            />
            <Route
              exact={true}
              path="/cancellation"
              element={<CancellationAndRefund />}
            />
            <Route
              exact={true}
              path="/orders"
              element={<Protected Component={MyOrderWrapper} />}
            />
            <Route
              path="/#FAQ"
              element={<FAQ />}
            />
            <Route
              path="/employee-management"
              element={<EmployeeManagement />}
            />
            <Route
              path="/payroll-management"
              element={<PayrollManagement />}
            />
            <Route
              path="/leave-attendance-management"
              element={<LeaveAttendance />}
            />
            <Route
              path="/attendance-management"
              element={<CompensationTaxes />}
            />
            <Route
              path="/asset-project"
              element={<AssetProject />}
            />
            <Route
              path="/document-management"
              element={<DocumentManagement />}
            />
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
};
export default WrapperComponent;
