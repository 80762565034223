import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import First from "../../assets/images/d5.png";
import second from "../../assets/images/d6.png";
import third from "../../assets/images/d7.png";
import fourth from "../../assets/images/d8.png";
import fifth from "../../assets/images/d2.png";
import ninth from "../../assets/images/d3.png";
import seventh from "../../assets/images/d1.png";
import eight from "../../assets/images/d4.png";
import sixth from "../../assets/images/d9.png";

function PhotoGallery() {
  const [previewImage, setPreviewImage] = useState(null);
  const photos = [First, second, third, fourth, fifth, sixth, seventh, eight, ninth];

  const photoItemStyle = {
    position: "relative",
    overflow: "hidden",
    borderRadius: "10px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
  };

  const photoStyle = {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    transition: "transform 0.3s ease",
    borderRadius: "10px",
    maxHeight: "300px",
  };

  const handleMouseEnter = (e, imgRef) => {
    e.target.style.transform = "scale(1.05)";
    imgRef.current.style.transform = "scale(1.1)";
  };

  const handleMouseLeave = (e, imgRef) => {
    e.target.style.transform = "scale(1)";
    imgRef.current.style.transform = "scale(1)";
  };

  const handleImageClick = (photo) => {
    setPreviewImage(photo);
  };

  const closeModal = () => {
    setPreviewImage(null);
  };

  return (
    <div className="container mt-4">
      <h1 className="text-center mb-5">
        Our <span style={{ color: "#008080" }}> Portfolio</span>
      </h1>

      <div className="row g-3">
        {photos.map((photo, index) => {
          const imgRef = useRef(null);

          return (
            <div
              className="col-12 col-sm-4 col-md-4 col-lg-4"
              key={index}
              style={photoItemStyle}
              onMouseEnter={(e) => handleMouseEnter(e, imgRef)}
              onMouseLeave={(e) => handleMouseLeave(e, imgRef)}
              onClick={() => handleImageClick(photo)}>
              <img
                ref={imgRef}
                src={photo}
                alt={`photo-${index}`}
                style={photoStyle}
              />
            </div>
          );
        })}
      </div>

      {/* Modal */}
      {previewImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            cursor: "zoom-out",
          }}
          onClick={closeModal}>
          <img
            src={previewImage}
            alt="Preview"
            style={{
              width: "80%",
              height: "auto",
              borderRadius: "10px",
              transform: "scale(1.2)",
              transition: "transform 0.3s ease",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default PhotoGallery;
