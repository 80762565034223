import React, { useEffect } from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import addctc from "../assets/images/addctc.jpg";
import addempctc2 from "../assets/images/addempctc2.jpg";
import createpayroll from "../assets/images/createpayroll.jpg";
import payrollsummary from "../assets/images/payrollsummary.png";
import payrolldetails from "../assets/images/payrolldetails.png";
import { getAccessToken } from "../components/auth/HandleCookies";

const PayrollManagement = () => {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Payroll | Host" : "Payroll | HRMS";
  }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Payroll Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addctc}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Employee CTC
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Employee CTC</strong> form documents an employee’s <strong>Cost to Company (CTC)</strong> details, ensuring accurate payroll management. It includes:
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Employee Information:</strong> Employee Name, Date of Joining.
                </li>
                <li>
                  🔹 <strong>CTC Details:</strong> Annual CTC, Start Month, Revision Due Month, Designation.
                </li>
                <li>
                  🔹 <strong>Salary Components:</strong> Variable Pay, Insurance, Gross Salary, Basic Salary, HRA, and other allowances.
                </li>
              </ul>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This structured approach facilitates <strong>financial transparency</strong> and <strong>efficient salary processing</strong>
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "justify" }}>
                All Taxes & Deductions
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                This section focuses on configuring <strong>Provident Fund (PF), Employee State Insurance (ESI), and Professional Tax (PT)</strong> within an employee’s salary structure. Each field
                outlines the <strong>employee and employer contributions</strong>, along with the calculation basis (e.g., a percentage of basic or gross salary).
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                By selecting or deselecting the respective checkboxes, HR can <strong>include or exclude specific deductions</strong> as needed.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                ensuring <strong>accurate payroll calculations and regulatory compliance</strong>.
              </p>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addempctc2}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={createpayroll}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "justify" }}>
                Create Payroll
              </h2>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", paddingRight: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Payroll Inputs:</strong> Select Year, Month, Holidays, Workdays, and Attendance.
                </li>
                <li>
                  🔹 <strong>Payable Days:</strong> Auto-calculates based on workdays and unpaid absences.
                </li>
                <li>
                  🔹 <strong>Create Payroll:</strong> Generates payroll for employees.
                </li>
                <li>
                  🔹 <strong>Adjustments:</strong> Modify attendance or workdays if needed.
                </li>
                <li>
                  🔹 <strong>Review:</strong> Verify details before processing payroll.
                </li>
                <li>
                  🔹 <strong>Finalize:</strong> Save changes or discard modifications.
                </li>
              </ul>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                Accurate inputs ensure <strong>correct payroll processing and compliance</strong>.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "justify" }}>
                Payroll Summary
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                The <strong>Payroll Summary</strong> provides a quick overview of a payroll period, including the{" "}
                <strong>pay period, payable days, reimbursements, pay date, total payroll cost, and employee count</strong>. It summarizes{" "}
                <strong>earnings, deductions, contributions, and loans</strong> while displaying the <strong>payroll status</strong> (executed or pending). The summary ensures accurate payroll
                calculations and financial transparency. Once verified, the <strong>EXECUTE PAYROLL</strong> button finalizes processing, enabling HR teams to efficiently complete payroll management.
              </p>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={payrollsummary}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={payrolldetails}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Payroll Details
              </h2>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Employee Name & Emp#:</strong> Identifies the employee and unique code.
                </li>
                <li>
                  🔹 <strong>Month/Year:</strong> Specifies the payroll period (e.g., Feb 2025).
                </li>
                <li>
                  🔹 <strong>Paid Days:</strong> Displays total payable days.
                </li>
                <li>
                  🔹 <strong>Gross Monthly & Total Payment:</strong> Shows earnings before deductions and net paid amount.
                </li>
                <li>
                  🔹 <strong>Total Income & Net Salary:</strong> Summarizes earnings and take-home pay after deductions.
                </li>
                <li>
                  🔹 <strong>Total Deduction:</strong> Lists all deductions (e.g., PF, ESI, TDS).
                </li>
                <li>
                  🔹 <strong>Status:</strong> Indicates payroll status (Executed, Warning, Created, Skipped).
                </li>
                <li>
                  🔹 <strong>EXECUTE PAYROLL:</strong> Finalizes the payroll process.
                </li>
              </ul>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This structured format helps HR efficiently review payroll details and resolve discrepancies.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PayrollManagement;
