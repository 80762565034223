import React, { useEffect } from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import leaverequest from "../assets/images/leavebalance.jpg";
import aa from "../assets/images/a.png";
import balance from "../assets/images/balance.jpg";
import { getAccessToken } from "../components/auth/HandleCookies";

const LeaveAttendance = () => {  
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Leave | Host" : "Leave | HRMS";
  }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Leave & Attendance Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={leaverequest}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "center" }}>
                Leave Balance
              </h2>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify" }}>
                The leave balance system helps employees track their leave efficiently. <strong>Entitled Leave</strong> is the total assigned leave, <strong>Availed Leave</strong> is what has been
                taken, <strong>Applied Leave</strong> includes pending requests, and <strong>Available Leave</strong> is the remaining balance.
              </p>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify" }}>
                Employees can request leave by selecting a <strong>Leave Type</strong> (Annual, Casual, or Sick) and choosing a <strong>Full-Day, First-Half, or Second-Half</strong> option.
              </p>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify" }}>
                They must specify the <strong>Start and End Dates</strong>, with the system calculating the <strong>Total Days Off</strong>, excluding weekends and holidays. This ensures a smooth and
                efficient leave management process.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "center" }}>
                Leave Grant
              </h2>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify" }}>
                The <strong>Leave Grant system</strong> allows employees to receive compensatory leave for extra work beyond regular hours. It includes details like <strong>Employee Name</strong> and{" "}
                <strong>Grant Reason</strong> and requires selecting a <strong>Leave Grant Type</strong> (e.g., compensatory leave). Employees must specify the <strong>Worked From</strong> and{" "}
                <strong>Worked To</strong> dates, with the <strong>Days Grant</strong> field indicating the awarded leave days.
              </p>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify" }}>
                The <strong>Expiration Date</strong> sets the last day the leave can be used, and optional comments can be added.
              </p>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={aa}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container ">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={balance}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Leave Balance
              </h2>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                The <strong>Add Leave Balance</strong> form allocates leave entitlements for a specific year. It includes <strong>Employee Name, Year, and Max Accrued Limit</strong> to define the
                maximum leave accumulation. The form displays <strong>Current, Previous, and Total Annual Leave</strong> for a clear overview.
              </p>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                It also breaks down <strong>leave categories</strong> (Annual, Casual, Sick, Unpaid), showing <strong>entitled, availed, and applied leave counts</strong>.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LeaveAttendance;
