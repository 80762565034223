import React, { useEffect } from "react";
import "./innerPage.css";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import emp1 from "../assets/images/emp1.png";
import emp2 from "../assets/images/emp2.png";
import addBank from "../assets/images/addBank.jpg";
import educationdetails from "../assets/images/educationdetails.png";
import { getAccessToken } from "../components/auth/HandleCookies";

const EmployeeManagement = () => {
  let accessToken = getAccessToken();
    useEffect(() => {
      document.title = accessToken ? "Emp | Host" : "Emp | HRMS";
    }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Employee Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={emp1}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                {" "}
                Add Employee
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Employee</strong> form ensures accurate employee record-keeping by capturing key details:
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Employee Code:</strong> A unique identifier for the employee.
                </li>
                <li>
                  🔹 <strong>Title:</strong> Select the appropriate title (e.g., Mr., Ms., Dr.).
                </li>
                <li>
                  🔹 <strong>First Name & Last Name:</strong> Mandatory fields for the employee's full name.
                </li>
                <li>
                  🔹 <strong>Date of Birth & Age:</strong> Provides birth details and auto-calculates age.
                </li>
                <li>
                  🔹 <strong>Gender:</strong> Select between Male or Female.
                </li>
                <li>
                  🔹 <strong>Marital Status:</strong> Choose the employee's marital status.
                </li>
                <li>
                  🔹 <strong>Father’s Name:</strong> Enter the father's name.
                </li>
                <li>
                  🔹 <strong>Contact Number:</strong> Includes country code and phone number for direct communication.
                </li>
                <li>
                  🔹 <strong>Personal Email:</strong> Employee’s personal email for correspondence.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "right" }}>
                Add Employee Information
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                The <strong>Add Employee Information</strong> section records essential employment details, including the <strong>Designation</strong> and <strong>Department</strong>, which define the
                employee's job title and assigned division. The <strong>Employment Type</strong> categorizes employees as full-time, part-time, or contractual, while the{" "}
                <strong>Reporting Manager</strong> ensures a clear reporting hierarchy. The <strong>Work Location</strong> and <strong>Date of Joining</strong> specify where the employee will be based
                and their official start date.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                Employees are assigned an <strong>Office Email</strong> for professional communication, and the <strong>Reportable & Remote Options</strong> determine whether they are required to work
                on-site or remotely.
              </p>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={emp2}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addBank}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Bank Details
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Bank Details</strong> form is designed to securely capture essential banking information for employees. It includes fields for selecting the{" "}
                <strong>Employee Name</strong> and entering critical financial details such as the <strong>Account Number, Bank Name, Branch Name, IFSC Code</strong>, and{" "}
                <strong>Account Holder Name</strong>. These mandatory fields, marked with an asterisk (*), ensure accuracy in salary processing and financial transactions.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This structured approach ensures <strong>seamless payroll management while maintaining data integrity</strong>.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-center px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "justify" }}>
                Education Details
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                When adding <strong>Education Details</strong>, ensure the following key fields are included:
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", paddingRight: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Employee Name:</strong> Select the employee for whom details are being added.
                </li>
                <li>
                  🔹 <strong>Qualification Type:</strong> Specify the level (e.g., Graduation, Post Graduation).
                </li>
                <li>
                  🔹 <strong>Course Name:</strong> Enter the name (e.g., BCA, MBA, etc.).
                </li>
                <li>
                  🔹 <strong>Course Type:</strong> Mention whether it was Regular, Distance, or Online.
                </li>
                <li>
                  🔹 <strong>Course Start & End Date:</strong> Provide the month and year of commencement and completion.
                </li>
                <li>
                  🔹 <strong>Grade:</strong> Enter the achieved grade (e.g., A+, B, etc.).
                </li>
                <li>
                  🔹 <strong>Stream:</strong> Indicate the field of study (e.g., Computer Science, Commerce).
                </li>
                <li>
                  🔹 <strong>Visible to Employee:</strong> Set the visibility status.
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={educationdetails}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EmployeeManagement;
