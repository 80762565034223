import React, { useEffect } from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import AssetsData from "./sectionData/assetsData";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import leaverequest from "../assets/images/asset.jpg";
import addAssettype from "../assets/images/addAssettype.jpg";
import addproject from "../assets/images/addproject.jpg";
import resource from "../assets/images/resource.png";
import addemprole from "../assets/images/addemprole.png";
import { getAccessToken } from "../components/auth/HandleCookies";

const AssetProject = () => {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Asset | Host" : "Asset | HRMS";
  }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Assets Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addAssettype}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Asset Type
              </h2>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Asset Type</strong> feature allows administrators to create asset categories by specifying an <strong>Asset Type Name</strong> (e.g., Laptop, Printer) and a{" "}
                <strong>Description</strong> with relevant details like model and specifications.
              </p>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                Once defined, the asset type can be <strong>saved</strong> for proper classification and tracking, ensuring <strong>consistency across all assets</strong>.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-end px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "right" }}>
                Add Asset
              </h2>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                The <strong>Add Asset</strong> feature enables efficient asset management by capturing key details:
              </p>
              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  <strong>Asset Name</strong> – Name of the asset (e.g., Laptop, Printer).
                </li>
                <li>
                  <strong>Asset Type</strong> – Specifies the category (e.g., Laptop).
                </li>
                <li>
                  <strong>Date of Purchase</strong> – Records when the asset was acquired.
                </li>
                <li>
                  <strong>Model Number</strong> – Unique identifier for the asset model.
                </li>
                <li>
                  <strong>Serial Number</strong> – Unique identifier for the specific asset unit.
                </li>
                <li>
                  <strong>Cost</strong> – Monetary value of the asset.
                </li>
                <li>
                  <strong>Location</strong> – Assigned physical location (e.g., Head Office).
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={leaverequest}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addproject}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Project
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Project</strong> form allows users to create and manage new projects efficiently. It includes essential fields such as{" "}
                <strong>Project Name, Duration (in months), and Kickoff Date</strong>, ensuring clear project definition and timeline tracking.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                Additionally, the <strong>Project Description</strong> section enables users to provide a detailed overview of the project's <strong>objectives, scope, and key functionalities</strong>
                .
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}></p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column align-items-end px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "right" }}>
                Add Asset
              </h2>
              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  <strong>Project Name (Required)</strong> – Select the project to which the employee will be assigned.
                </li>
                <li>
                  <strong>Employee Name (Required)</strong> – Choose the employee to be allocated to the selected project.
                </li>
                <li>
                  <strong>Designation</strong> – Displays the employee’s job title.
                </li>
                <li>
                  <strong>Employee Role (Required)</strong> – Define the role of the employee within the project.
                </li>
                <li>
                  <strong>From Date</strong> – Specify the start date of the employee's assignment.
                </li>
                <li>
                  <strong>To Date</strong> – Specify the end date of the assignment..
                </li>
                <li>
                  <strong>Location</strong> – Assigned physical location (e.g., Head Office).
                </li>
              </ul>
              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}></p>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={resource}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={addemprole}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Employee Role
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Add Employee Role</strong> feature allows users to define and add new roles for employees within a <strong>project or organization</strong>. To add a new employee role,
                users need to enter the desired <strong>Role Name</strong> in the provided input field, which is a required field marked with an asterisk (*).
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This functionality helps in <strong>organizing and managing employee roles efficiently</strong> in the system.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AssetProject;
