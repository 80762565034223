import React, { useEffect } from "react";
import IntlMessages from "../../util/IntlMessages";
import { getAccessToken } from "../../components/auth/HandleCookies";

function CancellationAndRefund() {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Cancellation | Host" : "Cancellation | HRMS";
  }, []);
  return (
    <>
      <>
        <div className="heading">
          <span>Cancellation and Refund</span>
        </div>
        <div className="card border-3 shadow-lg container-body mt-2 mb-4">
          <div className="card-body">
            <div style={{ fontFamily: "-moz-initial" }}>
              <p>
                <IntlMessages id="cancellation.and.refundable.l1" />
              </p>
              <p>
                <IntlMessages id="cancellation.and.refundable.l2" />
              </p>
              <p>
                <IntlMessages id="cancellation.and.refundable.l3" />
              </p>
              <p>
                <IntlMessages id="cancellation.and.refundable.l4" />
              </p>
              <p>
                <IntlMessages id="cancellation.and.refundable.l5" />
              </p>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default CancellationAndRefund;
