import React from "react";
import { Link } from "react-router-dom";

const FeaturedBox = ({ data }) => {
  return (
    <>
      <div className="service-item">
        <h3 className="featureTitle">{data?.feature_title}</h3>
        <div className="service-item-box">
          <ul className="featureDesc">
            {data?.feature_desc.map((desc, i) => (
              <li key={i}>{desc?.feature_item}</li>
            ))}
          </ul>
          <Link
            className="readMoreLink"
            to={data?.link}>
            Read More
          </Link>
        </div>
      </div>
    </>
  );
};

export default FeaturedBox;
