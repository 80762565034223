import React, { useEffect } from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import documentimg1 from "../assets/images/documentimg1.png";
import documentimg2 from "../assets/images/documentimg2.png";
import documentimg3 from "../assets/images/documentimg3.png";
import documentimg4 from "../assets/images/documentimg4.jpg";
import { getAccessToken } from "../components/auth/HandleCookies";

const DocumentManagement = () => {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Document | Host" : "Document | HRMS";
  }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Document Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={documentimg1}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Company Document
              </h2>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Document Category:</strong> Select the category (e.g., KYC, Policies, Forms).
                </li>
                <li>
                  🔹 <strong>Document Name*:</strong> Provide a clear and concise title (e.g., Aadhar Card, PAN Card).
                </li>
                <li>
                  🔹 <strong>Visible to Employee:</strong> Toggle Yes/No to specify employee access.
                </li>
                <li>
                  🔹 <strong>Dashboard:</strong> Toggle Yes/No to decide if it is displayed on the dashboard.
                </li>
              </ul>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This structured approach ensures efficient organization and seamless document sharing.
              </p>
            </Col>
          </Row>
        </Container>
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Add Employee Document
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                The <strong>Add Employee Document</strong> feature enables HR to efficiently upload and categorize employee-related documents. Users select the <strong>Employee Name</strong>, specify
                the <strong>Document Category</strong> (e.g., KYC, contract), and provide a <strong>Document Name</strong> for easy identification. A <strong>visibility toggle</strong> determines
                whether employees can access the document, and an additional <strong>dashboard toggle</strong> allows it to be prominently displayed if required. This structured approach ensures
                employee records remain
                <strong>organized, accessible, and well-categorized</strong>, streamlining document management within the system. ✅
              </p>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={documentimg2}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={documentimg3}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Document Details & Attachment
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This section provides details about all files associated with the document or employee record:
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>All Files Section:</strong> Displays a list of associated files.
                </li>
                <li>
                  🔹 <strong>File Name:</strong> Shows the uploaded file name (e.g., "Arjun.png").
                </li>
                <li>
                  🔹 <strong>Date Added:</strong> Indicates when the file was uploaded or last modified (e.g., "10-05-2024 12:56").
                </li>
                <li>
                  🔹 <strong>Size (KB):</strong> Provides file size for quick reference.
                </li>
                <li>
                  🔹 <strong>Pagination:</strong> Allows browsing through multiple files.
                </li>
                <li>
                  🔹 <strong>Add New Attachment:</strong> Button to upload additional files.
                </li>
                <li>
                  🔹🗑 <strong>Delete/Remove:</strong> Option to remove unwanted files.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Employee Document List
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This table provides an overview of all documents linked to each employee:
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Employee Name & Emp #:</strong> Identifies the employee associated with the document.
                </li>
                <li>
                  🔹 <strong>Document:</strong> Shows the specific type of document (e.g., AADHAR CARD, PAN CARD).
                </li>
                <li>
                  🔹 <strong>Category:</strong> Classifies the document (e.g., KYC, Educational Documents).
                </li>
                <li>
                  🔹 <strong>Approved Date:</strong> Indicates when the document was approved or verified.
                </li>
                <li>
                  🔹 <strong>Visible to Employee:</strong> Marks whether the document is accessible (✔ or ✘).
                </li>
                <li>
                  🔹 <strong>Status:</strong> Reflects the approval state (e.g., Approved, Unapproved, Pending).
                </li>
              </ul>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={documentimg4}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DocumentManagement;
