import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { clearCookies, getAccessToken } from "../auth/HandleCookies";
import Features from "../features/Features";
import { PricingPlan } from "../pricing-plan/PricingPlan";
import SubscriptionPlan from "../subscription/currentSubscription";
import { About } from "./About";
import CookiesComponent from "./CookiesComponent";
import Home from "./Home";
import YoutubeVideo from "../videos/YoutubeVideo";
import FAQ from "../faq/FAQ";
import PhotoGallery from "../gallery/gallery";
import ScheduleDemo from "./ScheduleDemo";
import Navbar from "../header/navbar";
import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
export default function LandingPage() {
  let accessToken = getAccessToken();
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { threshold: 0.6 }
    );

    Object.values(sectionsRef.current).forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => observer.disconnect();
  }, []);

  const profileData = Cookies.get("profile");

  useEffect(() => {
    const profileData = Cookies.get("profile");

    if (profileData) {
      try {
        const profile = JSON.parse(profileData);

        if (profile.roles?.[1] === "portal_user") {
          clearCookies();
          navigate("/#homeSection", { replace: true });
        } else {
          console.log("Profile:", profile);
        }
      } catch (error) {
        console.error("Error parsing profile data:", error);
        clearCookies();
        navigate("/#homeSection", { replace: true });
      }
    }
  }, [navigate]);

  const pageTitles = {
    "/#homeSection": "Home",
    "/#aboutWraper": "About",
    "/#featuresSection": "Feature",
    "/document-management": "Document",
    "/#pricingPlanSection": "Pricing",
    "/#FAQ": "FAQ",
  };

  useEffect(() => {
    const baseTitle = accessToken ? "Host" : "HRMS";
    const currentPath = location.pathname + location.hash; // Support for both pathname and hash
    const newTitle = pageTitles[currentPath] ? `${pageTitles[currentPath]} | ${baseTitle}` : `HRMS | Human Resource Management`;

    document.title = newTitle;
  }, [location, accessToken]);

  return (
    <>
      <Helmet>
        <title>HRMS | Human Resource Management | Employee Management | Payroll Management | Leave & attendance | Asset Management</title>
        <meta
          name="description"
          content="Simplify your HR management with HRMSHOST, a comprehensive cloud-based HRMS software. Manage employees and streamline payroll, leave and attendence, and more."
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#homeSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#featuresSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#pricingPlanSection"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#FAQ"
        />
        <link
          rel="canonical"
          href="https://hrmshost.com/#aboutWraper"
        />
      </Helmet>

      <Navbar activeSection={activeSection} />

      <div className="contentSection">
        {accessToken && (
          <>
            <div
              id="subscriptionSection"
              ref={(el) => (sectionsRef.current["subscriptionSection"] = el)}>
              <SubscriptionPlan />
            </div>
            <div
              id="pricingPlanSection"
              ref={(el) => (sectionsRef.current["pricingPlanSection"] = el)}>
              <PricingPlan />
            </div>
            <div
              id="featuresSection"
              ref={(el) => (sectionsRef.current["featuresSection"] = el)}>
              <Features />
            </div>
            <div
              id="FAQ"
              ref={(el) => (sectionsRef.current["FAQ"] = el)}>
              <FAQ />
            </div>
            <div
              id="videoSection"
              ref={(el) => (sectionsRef.current["videoSection"] = el)}>
              <YoutubeVideo />
            </div>
          </>
        )}
        {!accessToken && (
          <>
            <div
              id="homeSection"
              ref={(el) => (sectionsRef.current["homeSection"] = el)}>
              <Home />
            </div>
            <div
              id="aboutWraper"
              ref={(el) => (sectionsRef.current["aboutWraper"] = el)}>
              <About />
            </div>
            <div
              id="featuresSection"
              ref={(el) => (sectionsRef.current["featuresSection"] = el)}>
              <Features />
            </div>
            <div
              id="photoGallerySection"
              ref={(el) => (sectionsRef.current["photoGallerySection"] = el)}>
              <PhotoGallery />
            </div>
            <div
              id="pricingPlanSection"
              ref={(el) => (sectionsRef.current["pricingPlanSection"] = el)}>
              <PricingPlan />
            </div>
            <div
              id="FAQ"
              ref={(el) => (sectionsRef.current["FAQ"] = el)}>
              <FAQ />
            </div>
            <div
              id="videoSection"
              ref={(el) => (sectionsRef.current["videoSection"] = el)}>
              <YoutubeVideo />
            </div>
            <div
              id="scheduleDemoSection"
              ref={(el) => (sectionsRef.current["scheduleDemoSection"] = el)}>
              <ScheduleDemo />
            </div>
          </>
        )}
        <CookiesComponent />
      </div>
    </>
  );
}
