import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import { React, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Button, Row } from "reactstrap";
import hrmsHostLogo250by60 from "../../assets/images/hrmshost-logo-250x60.png";
import { AxiosAdminConfig } from "../../config/AxiosAdminConfig";
import { paymentApi, planUpgrade } from "../../redux/ApiLists";
import { currencyConverterWithoutRupee } from "../../util/Helper";
import IntlMessages from "../../util/IntlMessages";
import { getProfile } from "../auth/HandleCookies";
import "../payroll/paySlipPdf/paySlipPdf.css";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import Spinner from "../shared/spinner/Spinner";

const CreateOrderPopup = ({ openDialog, setOpenDialog, planId, showPayButton, setOpenSuccessDialog, isUpgradeEvent = false }) => {
  let uri = `${paymentApi}/${planId}`;
  if (isUpgradeEvent) {
    uri = `${paymentApi}/${planId}/${planUpgrade}`;
  }
  const [loading, setLoading] = useState(false);
  const loggedInUser = JSON.parse(getProfile());
  const [orderView, setOrderView] = useState();
  const [amountToAdjust, setAmountToAdjust] = useState();
  const invoiceRef = useRef(null);

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "a4",
      putOnlyUsedFonts: true,
      compress: true,
    });

    doc.html(invoiceRef.current, {
      callback(doc) {
        doc.save(`${"invoice"}`);
      },
      x: 20,
      y: 10,
      // autoPaging: false,
      width: 550, //target width in the PDF document
      windowWidth: 650, //window width in CSS pixels
    });
    console.log(doc.getFontList());
    console.log(doc.getFont());
  };

  useEffect(() => {
    getOrderView();
  }, []);

  const getOrderView = async () => {
    setLoading(true);
    await AxiosAdminConfig.get(uri)
      .then((response) => {
        if (isUpgradeEvent) {
          setOrderView(response.orderView);
          setAmountToAdjust(response.amountToAdjust);
        } else {
          setOrderView(response);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setOpenDialog(false);
      });
    setLoading(false);
  };

  const initiatePayment = async () => {
    setLoading(true);
    let orderDetailRes;
    await AxiosAdminConfig.post(uri)
      .then((res) => {
        orderDetailRes = res;
        setOpenDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error Occurred  : ", error);
      });

    if (orderDetailRes) {
      let paymentOptions = {
        key: process.env.REACT_APP_PAYMENT_KEY_ID, // Enter the Key ID generated from the Dashboard
        amount: orderDetailRes.totalPrice, // Amount is in currency subunits. Default currency is INR. Hence, 500 refers to 500 paise
        currency: "INR",
        name: "Teksaar Innovations Pvt Ltd.",
        description: "Subscription Plan buy/upgrade to " + orderView?.planViewDto?.planName,
        image: hrmsHostLogo250by60,
        order_id: orderDetailRes.razorpayOrderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: paymentSuccessHandler,
        prefill: {
          name: loggedInUser.firstName + " " + loggedInUser.lastName,
          email: loggedInUser.email,
          // contact: "8855775522",
        },
        // "notes": {
        //     "address": "39, Agrasen Nagar, Mandsaur"
        // },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            window.location.reload();
          },
        },
      };

      let rzp1 = new Razorpay(paymentOptions);
      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        let errorObj = {
          razorpayOrderId: response.error.metadata.order_id,
          razorpayPaymentId: response.error.metadata.payment_id,
          errorObj: JSON.stringify(response.error),
        };
        console.log("~ errorObj:", errorObj);
        toast.success("Payment Failed");
      });
    }
  };

  const paymentSuccessHandler = (response) => {
    let requestObj = {
      razorpayOrderId: response.razorpay_order_id,
      razorpayPaymentId: response.razorpay_payment_id,
      razorpaySignature: response.razorpay_signature,
      upgrade: isUpgradeEvent,
    };
    setOpenSuccessDialog(true);
    toast.success("Plan Upgraded!");
    // setOpenDialog(true);
    // AxiosAdminConfig.put(`${paymentApi}`, requestObj)
    //   .then((res) => {
    //     toast.success("Plan Upgraded!");
    //     window.location.href = "/";
    //   })
    //   .catch((error) => {
    //     console.error("Error Occurred : ", error);
    //   });
  };

  const redirectToHome = () => {
    window.location.href = "/";
    window.location.reload();
    setOpenDialog(false);
  };

  return (
    <>
      <ModelSelfFooter
        fullWidth={true}
        open={openDialog}
        heading={<IntlMessages id="form.text.plan.detail" />}
        closePopUp={
          <Tooltip
            title="Close"
            placement="top">
            <CloseIcon
              fontSize="large"
              onClick={() => setOpenDialog(false)}
              className="popUpCloseIcon"
            />
          </Tooltip>
        }
        closeFn={() => setOpenDialog(false)}
        maxWidth="xs">
        {!orderView && loading ? (
          <div className="text-center">
            <Spinner />{" "}
          </div>
        ) : (
          <>
            <div className="exportButton">
              {!showPayButton && (
                <Button
                  color="primary"
                  className=""
                  onClick={handleGeneratePdf}
                  size="sm">
                  Download as PDF &nbsp; <FileDownloadIcon />
                </Button>
              )}
            </div>
            <div
              style={{ fontFamily: "-moz-initial" }}
              id="paySlipSection"
              className="content container-fluid paySlipSection"
              ref={invoiceRef}>
              <div className="row fit-content">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <Row>
                        <div className="paySlipHeader">
                          <div className="headerInfo">
                            <h5 className="payslip-title">Order Detail</h5>
                          </div>
                        </div>
                      </Row>
                      <div className="row">
                        <div className="col-sm-6">
                          <address className="addressText">
                            <strong>To Company:</strong>
                            <br />
                            {orderView?.toCompany?.companyName}
                            <br />
                            {orderView?.toCompany?.addressLine1 + ", " + orderView?.toCompany?.addressLine2},
                            <br />
                            {`${orderView?.toCompany?.city}  (${orderView?.toCompany?.state}) ${orderView?.toCompany?.country}`}
                          </address>
                        </div>
                        <div className="col-sm-6 text-right">
                          <address className="addressText">
                            <strong>From Company:</strong>
                            <br />
                            {orderView?.fromCompany?.companyName}
                            <br />
                            {(orderView?.fromCompany?.addressLine1 ? orderView.fromCompany.addressLine1 + ", " : "") + (orderView?.fromCompany?.addressLine2 ? orderView.fromCompany.addressLine2 : "")}
                            <br />
                            {`${orderView?.fromCompany?.city ? orderView.fromCompany.city : ""}  
                      ${orderView?.fromCompany?.state ? "(" + orderView.fromCompany.state + ")" : ""} 
                      ${orderView?.fromCompany?.country ? orderView.fromCompany.country : ""}`}
                          </address>
                        </div>
                        <div className="row">
                          <span>
                            <b> GST Number :</b> {orderView?.toCompany?.gstNumber}
                          </span>
                          <span>
                            <b>Pan : </b>
                            {orderView?.toCompany?.pan}
                          </span>
                        </div>
                      </div>
                      &nbsp;
                      <div className="row">
                        <div className="col-sm-12">
                          <div>
                            <table className="table-bordered invoiceTable">
                              <thead>
                                <th>S. No.</th>
                                <th>Plan Name</th>
                                <th>Plan Details</th>
                                <th>Price</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>{orderView?.planViewDto?.planName}</td>
                                  <td>
                                    Max-Asset : {orderView?.planViewDto?.maxAsset}
                                    <br /> Max-Project :{orderView?.planViewDto?.maxProject}
                                    <br /> Max-Employee : {orderView?.planViewDto?.maxEmployee}
                                    <br /> Max-Document : {orderView?.planViewDto?.maxDocument}
                                  </td>

                                  <td className="text-end">{currencyConverterWithoutRupee(orderView?.planViewDto?.planPrice / 100)}</td>
                                </tr>
                                {orderView?.planViewDto?.discountPercent > 0 && (
                                  <tr className="borderNone">
                                    <td colspan="2">Offer: {orderView?.planViewDto?.offerName}</td>
                                    <td colspan="1">Discount ({orderView?.planViewDto?.discountPercent}%)</td>
                                    <td>{currencyConverterWithoutRupee(orderView?.planViewDto?.discountAmount / 100)}</td>
                                  </tr>
                                )}
                                {isUpgradeEvent && amountToAdjust > 0 && (
                                  <tr className="borderNone">
                                    <td colspan="2"></td>
                                    <td colspan="1">Amount from existing plans to Adjust</td>
                                    <td>{currencyConverterWithoutRupee(amountToAdjust / 100)}</td>
                                  </tr>
                                )}
                                <tr className="borderNone">
                                  <td colspan="2"></td>
                                  <td colspan="1">Net Amount</td>
                                  <td>{currencyConverterWithoutRupee(orderView?.planViewDto?.netPrice / 100)}</td>
                                </tr>
                                <tr className="borderNone">
                                  <td colspan="2"></td>
                                  <td colspan="1">CGST ({orderView?.planViewDto?.cgstRate}%)</td>
                                  <td>{currencyConverterWithoutRupee(orderView?.planViewDto?.cgstAmount / 100)}</td>
                                </tr>
                                <tr className="borderNone">
                                  <td colspan="2"></td>
                                  <td colspan="1">SGST ({orderView?.planViewDto?.sgstRate}%)</td>
                                  <td>{currencyConverterWithoutRupee(orderView?.planViewDto?.sgstAmount / 100)}</td>
                                </tr>
                                <tr className="borderNone">
                                  <td colspan="2"></td>
                                  <td colspan="1">IGST ({orderView?.planViewDto?.igstRate}%)</td>
                                  <td>{currencyConverterWithoutRupee(orderView?.planViewDto?.igstAmount / 100)}</td>
                                </tr>
                                <tr className="borderNone">
                                  <td colspan="2"></td>
                                  <td colspan="1">
                                    <b>Total Price (Rs.)</b>
                                  </td>
                                  <td>
                                    <b>{currencyConverterWithoutRupee(orderView?.planViewDto?.totalPrice / 100)}</b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4">
                      <div className="invoiceFooter">
                        {showPayButton && (
                          <Button
                            onClick={initiatePayment}
                            className="buttonSize mt-3"
                            color="primary"
                            variant="contained"
                            disabled={loading ? true : false}>
                            {loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            {loading && <span>Pay Now</span>}
                            {!loading && <span>Pay Now</span>}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </ModelSelfFooter>
    </>
  );
};

export default CreateOrderPopup;
