import Axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { BiLockAlt, BiUser, BiShow, BiHide } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Form, FormGroup, Input, Row } from "reactstrap";
import * as yup from "yup";
import { loginCompany } from "../../redux/ApiLists";
import IntlMessages from "../../util/IntlMessages";
import "./authStyle.css";
import ForgetPassword from "./ForgetPassword";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import { clearCookies, setCookies } from "./HandleCookies";

export default function SignIn({ setShowLogin }) {
  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [openForgotPassword, setForgotPassword] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    clearCookies();
  }, []);
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  let validationSchema = yup.object().shape({
    username: yup.string().trim().required("Please Enter Username"),
    password: yup.string().trim().required("Please Enter Password"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit: (values) => {
      setIsFormSubmitted(true);
      handleSubmit(values);
    },
    validationSchema: validationSchema,
  });

  const recaptchaInstance = useRef(null);

  const verifyCallback = function (response) {
    if (response) {
      setCaptchaToken(response);
      setVerified(true);
    }
  };

  const expireCallback = function (response) {
    setVerified(false);
  };
  const renderErrorMessage = (field) => {
    return formik.touched[field] && <div className="text-danger">{formik.errors[field]}</div>;
  };

  const onForgotPasswordClick = () => {
    setForgotPassword(true);
  };

  const handleSubmit = async (values) => {
    if (!verified) {
      return;
    }
    setLoading(true);
    await Axios.post(
      `${process.env.REACT_APP_ERMS_ADMIN_API}${loginCompany}?token=${captchaToken}`,
      { username: values.username.trim(), password: values.password.trim() },
      {
        headers: {
          app_id: process.env.REACT_APP_APPLICATION_ID,
        },
      }
    )
      .then((response) => {
        if (response) {
          const userRole = response.data?.data?.profile?.roles?.[0];
          const portalUser = response.data?.data?.profile?.roles?.[1];

          if (userRole === "portal_admin") {
            setResponse(response);
            setCookies(response.data.data);
            toast.success("Welcome " + response.data.data.profile.firstName + "!");
            setTimeout(() => {
              window.location.replace("/");
              recaptchaInstance.current.reset();
              setVerified(false);
              setShowLogin(false);
            }, 1000);
          } else if (portalUser === "portal_user") {
            toast.error("You don't have the authority to log in.");
            recaptchaInstance.current.reset();
            setVerified(false);
          } else {
            toast.error("Invalid response");
            recaptchaInstance.current.reset();
            setVerified(false);
          }
        }
      })
      .catch((err) => {
        toast.error("Invalid Username or Password");
        recaptchaInstance.current.reset();
        setVerified(false);
      });
    setLoading(false);
  };

  const handleClosePopup = () => {
    setShowLogin(false);
  };

  return (
    <>
      <ModelSelfFooter
        open={true}
        closePopUp={
          <div className="closeIcon">
            <Tooltip
              title="Close"
              placement="top">
              <CloseIcon
                fontSize="large"
                onClick={handleClosePopup}
                className="popUpCloseIcon"
              />
            </Tooltip>
          </div>
        }
        className="modelwrapper"
        fullWidth={true}
        closeFn={handleClosePopup}>
        <div className="container login-6">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="form-section">
                <div className="formTittle">
                  <h4>
                    <IntlMessages id="login.title.portal-sign-in" />
                  </h4>
                </div>
                <div className="login-inner-form">
                  <Form
                    onKeyPress={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onSubmit={formik.handleSubmit}>
                    <FormGroup>
                      <Input
                        type="text"
                        className="form-control loginUser inputFeild"
                        id="floatingInput"
                        name="username"
                        value={formik.values.username.trim()}
                        placeholder="Your Email"
                        onChange={formik.handleChange}
                      />
                      <BiUser />
                      {renderErrorMessage("username")}
                    </FormGroup>

                    <FormGroup>
                      <Input
                        type={showPassword ? "text" : "password"}
                        className="form-control loginUser passwordViewIcon inputFeild"
                        id="floatingPassword"
                        name="password"
                        value={formik.values.password.trim()}
                        onChange={formik.handleChange}
                        placeholder="Password"
                      />
                      <BiLockAlt className="lock-icon" />
                      <button
                        type="button"
                        onClick={togglePasswordVisibility}
                        className="toggle-password-visibility"
                        aria-label={showPassword ? "Hide password" : "Showpassword"}>
                        {showPassword ? <BiHide /> : <BiShow />}
                      </button>
                      {renderErrorMessage("password")}
                    </FormGroup>

                    <FormGroup>
                      <ReCAPTCHA
                        className="captch-resize"
                        ref={recaptchaInstance}
                        sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                        render="explicit"
                        onChange={verifyCallback}
                        onExpired={expireCallback}
                      />
                      {isFormSubmitted && !verified && <span style={{ color: "red", fontSize: "12px" }}>Please check the Captcha</span>}
                    </FormGroup>

                    <Row>
                      <Col className="text-center">
                        <div className="submitButton">
                          <button
                            type="submit"
                            className={loading ? "button-disable" : "loginButton"}
                            disabled={loading ? true : false}>
                            {loading && (
                              <i
                                className="fa fa-refresh fa-spin"
                                style={{ marginRight: "5px" }}
                              />
                            )}
                            {loading && (
                              <span>
                                <IntlMessages id="button.text.logging" />
                              </span>
                            )}
                            {!loading && (
                              <span>
                                <IntlMessages id="button.text.login" />
                              </span>
                            )}
                          </button>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="text-center">
                        <div className="forgetPassword">
                          Forgot Password &nbsp;
                          <Link
                            to={"#"}
                            onClick={onForgotPasswordClick}
                            className="redirectLink">
                            Click here
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openForgotPassword && (
          <ForgetPassword
            openForgotPassword={openForgotPassword}
            setForgotPassword={setForgotPassword}
          />
        )}
      </ModelSelfFooter>
    </>
  );
}
