import CloseIcon from "@mui/icons-material/Close";
import { Tooltip } from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ModelSelfFooter from "../shared/Model/ModelSelfFooter";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import IntlMessages from "../../util/IntlMessages";

const CompanyRegistrationWrapper = ({ plan, setShowReg }) => {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [company, setCompanyDetail] = useState();
  const [counter, setCounter] = useState(60);
  const [disableResendOtp, setDisableResendOtp] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [intervalIdState, setIntervalIdState] = useState();
  const [oldDetail, setOldDetail] = useState({
    cName: "",
    cEmail: "",
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const redirectToLogin = () => {
    setShowReg(false);
    window.location.href = "/?showLogin=true";
  };
  useEffect(() => {
    if (activeStep == 0) {
      setOldDetail({
        cName: company?.companyName,
        cEmail: company?.companyEmail,
      });
    }
    if (company) {
      if (activeStep == 0 && !(oldDetail.cEmail == company?.companyEmail)) {
        stopCounter();
        sendOtp();
      } else {
        if (activeStep == 0) {
          handleNext();
          setDisableResendOtp(true);
          setShowCounter(true);
        }
      }

      if (activeStep == 1) verifyOtp();
      if (activeStep == 2) signup();
    }
  }, [company]);

  const sendOtp = () => {
    setLoading(true);
    setShowCounter(false);
    Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}public/send-otp?email=${encodeURIComponent(company.companyEmail)}`)
      .then((res) => {
        setLoading(false);
        handleNext();
        setDisableResendOtp(true);
        setShowCounter(true);
      })
      .catch((err) => {
        let errorResponse = JSON.parse(err.request.response);
        setLoading(false);
        toast.error(<IntlMessages id={errorResponse.message} />);
      });
  };

  const resendOtp = () => {
    setShowCounter(false);
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}public/send-otp?email=${encodeURIComponent(company.companyEmail)}`)
      .then((res) => {
        setDisableResendOtp(true);
        setShowCounter(true);
        setLoading(false);
      })
      .catch((err) => {
        let errorResponse = JSON.parse(err.request.response);
        toast.error(errorResponse.message);
        setLoading(false);
      });
  };

  let count = 60;
  let intervalId;

  function startCounter() {
    intervalId = setInterval(() => {
      if (count == 1) {
        setDisableResendOtp(false);
        setShowCounter(false);
        stopCounter();
      }
      if (showCounter) {
        setCounter((p) => p - 1);
        count--;
      }
    }, 1000);
    setIntervalIdState(intervalId);
  }

  function stopCounter() {
    clearInterval(intervalIdState);
    setCounter(60);
  }

  useEffect(() => {
    if (showCounter) {
      stopCounter();
      startCounter();
    }
  }, [showCounter]);

  const verifyOtp = () => {
    setLoading(true);
    Axios.get(`${process.env.REACT_APP_ERMS_ADMIN_API}public/verify-otp?email=${encodeURIComponent(company.companyEmail)}&otp=${company.otp}`)
      .then((res) => {
        setLoading(false);
        handleNext();
      })
      .catch((err) => {
        console.log("err ", err);
        let errorResponse = JSON.parse(err.request.response);
        toast.error(<IntlMessages id={"invalid.expired.otp"} />);
        setLoading(false);
      });
  };

  const signup = () => {
    setLoading(true);
    let companyDetail = { ...company, planId: plan.id };
    Axios.post(`${process.env.REACT_APP_ERMS_ADMIN_API}public/sign-up`, companyDetail)
      .then((res) => {
        toast.success("Sign-up Successful");
        handleNext();
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error in sign-up ", err);
        let errorResponse = JSON.parse(err.request.response);
        toast.error(errorResponse.message);
      });
  };

  return (
    <>
      <ModelSelfFooter
        open={true}
        closePopUp={
          <div className="closeIcon">
            <Tooltip
              title="Close"
              placement="top">
              <CloseIcon
                fontSize="large"
                style={{ color: "#d61c5a" }}
                onClick={() => setShowReg(false)}
                className="popUpCloseIcon"
              />
            </Tooltip>
          </div>
        }
        closeFn={() => setShowReg(false)}>
        {activeStep == 0 && (
          <Step1
            company={company}
            setCompanyDetail={setCompanyDetail}
            loading={loading}
            setShowReg={setShowReg}
            plan={plan}
          />
        )}
        {activeStep == 1 && (
          <Step2
            handleBack={handleBack}
            company={company}
            setCompanyDetail={setCompanyDetail}
            loading={loading}
            setShowReg={setShowReg}
            counter={counter}
            showCounter={showCounter}
            disableResendOtp={disableResendOtp}
            resendOtp={resendOtp}
          />
        )}
        {activeStep == 2 && (
          <Step3
            handleNext={handleNext}
            company={company}
            setCompanyDetail={setCompanyDetail}
            loading={loading}
            setShowReg={setShowReg}
          />
        )}
        {activeStep == 3 && <Step4 redirectToLogin={redirectToLogin} />}
      </ModelSelfFooter>
    </>
  );
};
export default CompanyRegistrationWrapper;
