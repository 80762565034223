import React, { useEffect } from "react";
import InnerPageBanner from "./reusebleContent/InnerPageBanner";
import Navbar from "../components/header/navbar";
import { Col, Container, Row } from "reactstrap";
import Createcalender from "../assets/images/Createcalender.png";
import attendancemap from "../assets/images/attendancemap.png";
import selectdays from "../assets/images/selectdays.jpg";
import summary from "../assets/images/summary.png";
import { getAccessToken } from "../components/auth/HandleCookies";

const CompensationTaxes = () => {
  let accessToken = getAccessToken();
  useEffect(() => {
    document.title = accessToken ? "Attendance | Host" : "Attendance | HRMS";
  }, []);
  return (
    <>
      <Navbar />
      <InnerPageBanner title="Attendance Management" />
      <div className="pageContentWrapper">
        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={Createcalender}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Create Calendar
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Create Calendar</strong> feature helps in <strong>planning and managing schedules</strong> efficiently. Users can select the <strong>year and month</strong> to generate a
                structured calendar for tracking <strong>workdays, meetings, and attendance</strong>.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This ensures <strong>better organization and time management</strong>. Once confirmed, users can <strong>proceed</strong> with creating the calendar.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 mt-3 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Attendance Details
              </h2>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Month & Year:</strong> February 2025
                </li>
                <li>
                  🔹 <strong>Weekends:</strong> Marked in Gray
                </li>
                <li>
                  🔹 <strong>Attendance Created:</strong> Marked in Green
                </li>
                <li>
                  🔹 <strong>Public Holidays:</strong> Marked in Yellow
                </li>
                <li>
                  🔹 <strong>Sync Attendance:</strong> Marked in Purple
                </li>
                <li>
                  🔹 <strong>Freeze Calendar:</strong> Active
                </li>
              </ul>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "justify", maxWidth: "90%" }}>
                This calendar efficiently tracks <strong>attendance, working days, holidays, and weekends</strong> for accurate record-keeping and workforce management.
              </p>
            </Col>

            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={attendancemap}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={selectdays}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Select Days for Calendar
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This feature allows users to classify specific dates as <strong>Workday, Weekend, or Holiday</strong>. By selecting a date (e.g., <strong>19-04-2025</strong>) and choosing an option,
                the system automatically updates the calendar.
              </p>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                This classification helps in <strong>managing attendance, scheduling, and payroll calculations</strong>.
              </p>
            </Col>
          </Row>
        </Container>

        <Container className="my-5 animated-container">
          <Row className="align-items-center">
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex flex-column align-items-start px-4">
              <h2
                className="mb-3"
                style={{ color: "teal", fontWeight: "bold", fontSize: "2rem", textAlign: "left" }}>
                Attendance Summary
              </h2>

              <p
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", maxWidth: "90%" }}>
                The <strong>Attendance Summary</strong> provides a structured overview of workforce presence, ensuring efficient tracking of attendance, leaves, and remote work schedules.
              </p>

              <ul
                className="text-muted"
                style={{ fontSize: "1.1rem", lineHeight: "1.6", textAlign: "left", paddingLeft: "1rem", maxWidth: "90%" }}>
                <li>
                  🔹 <strong>Overview:</strong>
                </li>
                <ul>
                  <li>
                    🔹 <strong>Weekends:</strong> 8
                  </li>
                  <li>
                    🔹 <strong>Public Holidays:</strong> 2 (13th Feb - Marked in Yellow)
                  </li>
                  <li>
                    🔹 <strong>Total Attendance Recorded:</strong> 18
                  </li>
                  <li>
                    🔹 <strong>Total Working Days:</strong> 18
                  </li>
                </ul>
                <li>
                  📌 <strong>Legend:</strong>
                </li>
                <ul>
                  <li>
                    🔹 <strong>Present:</strong> Employees marked as present
                  </li>
                  <li>
                    🔹 <strong>Leave:</strong> Approved leaves
                  </li>
                  <li>
                    🔹 <strong>Unpaid Leave:</strong> Employees absent without leave approval
                  </li>
                  <li>
                    🔹 <strong>Half Day:</strong> Employees who worked half a day
                  </li>
                  <li>
                    🔹 <strong>Remote:</strong> Employees working remotely
                  </li>
                </ul>
              </ul>
            </Col>
            <Col
              xs={12}
              md={12}
              xl={6}
              className="d-flex justify-content-center mb-4 mb-md-0">
              <div
                className="position-relative w-100 image-container"
                style={{ maxWidth: "85%" }}>
                <img
                  src={summary}
                  alt="Leave & Attendance Management"
                  className="img-fluid rounded shadow w-100 hover-zoom"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CompensationTaxes;
