export const monthShortName = (dateValue) => {
  const date = new Date(dateValue);
  return date.toLocaleString("en-US", {
    month: "short",
  });
};

export const dayShortName = (dateValue) => {
  const d = new Date(dateValue);
  switch (d.getDay()) {
    case 0:
      return "Sun";
    case 1:
      return "Mon";
    case 2:
      return "Tue";
    case 3:
      return "Wed";
    case 4:
      return "Thu";
    case 5:
      return "Fri";
    case 6:
      return "Sat";
  }
};

export const toMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return monthShortName(date);
};

export const monthIn2Digit = (dateValue) => {
  const date = new Date(dateValue);
  switch (date.getMonth()) {
    case 0:
      return "01";
    case 1:
      return "02";
    case 2:
      return "03";
    case 3:
      return "04";
    case 4:
      return "05";
    case 5:
      return "06";
    case 6:
      return "07";
    case 7:
      return "08";
    case 8:
      return "09";
    case 9:
      return "10";
    case 10:
      return "11";
    case 11:
      return "12";
  }
};

export const dayAndMonth = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  return ("0" + date).slice(-2) + " " + monthShortName(dateValue);
};

export const dateTimeFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return ("0" + date).slice(-2) + "-" + month + "-" + year + "  " + d.toString().substr(16, 5);
};

export const dateFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d);
  const year = d.getFullYear();
  return ("0" + date).slice(-2) + "-" + month + "-" + year;
};

export const dateEventFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const date = d.getDate();
  const month = monthShortName(d.getMonth());
  const year = d.getFullYear();
  return ("0" + date).slice(-2) + " " + month + " " + year;
};

export const yearFormatter = (dateValue) => {
  const d = new Date(dateValue);
  const year = d.getFullYear();
  return year;
};

export const generateUsernameFromEmail = (email) => {
  let strArr = email.split("@");
  let strArr2 = strArr[1].split(".");
  return strArr[0] + "@" + strArr2[0];
};

export const ROLES = {
  portal_admin: [
    {
      label: "Portal User",
      role: "portal_user",
    },
    {
      label: "Employee",
      role: "employee",
    },
  ],
  portal_user: [
    {
      label: "Employee",
      role: "employee",
    },
  ],
};

export const currencyConverter = (amount) => {
  return amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};

export const currencyConverterWithoutRupee = (amount) => {
  const newAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
  return newAmount.substring(1, newAmount.length);
};

export function currencyConverterForPlanBoxes(amount) {
  return (amount / 100)
    .toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
    })
    .replace(/(\.|,)00$/g, "");
}

export const currencyConverterForPaise = (amount) => {
  return (amount / 100).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};
export const dateFormateChange = (dateValue) => {
  const d = new Date(dateValue);
  const month = d.toLocaleString("default", { month: "long" });
  const day = d.getDate();
  const year = d.getFullYear();

  return `${month} ${day}, ${year}`;
};
