import { getAccessToken, getCompanyCode } from "../auth/HandleCookies";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPlan } from "../../redux/actions";
import { dateFormateChange } from "../../util/Helper";
import { toast } from "react-toastify";

const Message = ({ style, message }) => {
  return (
    <div style={style}>
      <marquee className="forSubscription-error">{message}</marquee>
    </div>
  );
};

const ValidateSubscription = () => {
  const companyCode = getCompanyCode();
  const accessToken = getAccessToken();

  const dispatch = useDispatch();
  const currentPlanFromStore = useSelector((store) => {
    return store.companySubscriptionReducer.currentPlan;
  });

  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (!currentPlanFromStore.data && currentPlanFromStore.message) {
      toast.error(currentPlanFromStore.message);
    } else if (currentPlanFromStore.data && Object.keys(currentPlanFromStore.data).length > 0 && !currentPlanFromStore.message) {
      setCurrentPlan(currentPlanFromStore.data);
    } else {
      dispatch(getCurrentPlan());
    }
  }, [currentPlanFromStore]);

  if (companyCode && accessToken) {
    if (currentPlan && currentPlan?.planEndDate) {
      const planEndDate = new Date(currentPlan.planEndDate);
      const todayDate = new Date();

      if (planEndDate.getTime() < todayDate.getTime()) {
        return (
          <Message
            style={{ backgroundColor: "#FFFF80", color: "red" }}
            message={
              <>
                Your subscription expired on {dateFormateChange(currentPlan.planEndDate)} Please log in to{" "}
                <a
                  href={process.env.REACT_APP_HRMSHOST_URL}
                  target="_blank"
                  rel="noopener noreferrer">
                  hrmshost.com
                </a>{" "}
                to renew or upgrade your subscription.
              </>
            }
          />
        );
      }
    }
    return null;
  }
};

export default ValidateSubscription;
